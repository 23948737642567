<template>
  <v-card class="fill-height d-flex flex-column">
    <v-card-title>{{ fopstepstemplate.name || "" }}</v-card-title>
    <v-card-subtitle>
      <v-col>
        <v-row
          v-for="x in fopstepstemplate.steps"
          :key="x._id"
          dense
        >
          <v-icon
            small
            class="pr-3"
          >
            mdi-account-multiple
          </v-icon>
          <span>
            {{ getGroupName(x.group) }}
          </span>
        </v-row>
      </v-col>
    </v-card-subtitle>
    <v-spacer />
    <v-card-actions>
      <v-row
        justify="center"
        dense
      >
        <v-col cols="auto">
          <v-btn
            color="orange"
            dark
            class="ma-2 white--text"
            @click="$emit('edit')"
          >
            MODIFIER<v-icon color="white">
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="red"
            dark
            class="ma-2 white--text"
            @click="$emit('delete')"
          >
            SUPPRIMER<v-icon color="white">
              mdi-delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import utils from "@/utils";

export default {
  props: ["fopstepstemplate", "groups"],
  data: () => ({}),
  methods: {
    getFieldIcon: utils.getFopFieldIcon,
    getGroupName(id) {
      let group = this.groups.find((x) => x._id == id);
      if (group) return group.name;
      else return "";
    },
  },
};
</script>
