<template>
  <div>
    <v-card flat>
      <v-card-title
        class="mb-2 text-h4"
        style="word-break: keep-all"
      >
        Gestion des chemins de validation
      </v-card-title>
      <v-card-subtitle>
        Cette page permet aux administrateurs de gérer les chemins de
        validations proposés pour les FOPs.
      </v-card-subtitle>

      <v-card-text v-if="!loading">
        <v-row align="center">
          <v-col cols="auto">
            <v-btn
              elevation="1"
              outlined
              rounded
              @click="modalCreate()"
            >
              Créer un chemin de validation
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="ml-auto"
          >
            <v-text-field
              v-model="search"
              outlined
              rounded
              hide-details="auto"
              dense
              label="Recherche"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="x in getFopStepsTemplates"
            :key="x._id"
            md="4"
            sm="6"
            cols="12"
          >
            <FopStepsCard
              :fopstepstemplate="x"
              :groups="groups"
              @edit="modalEdit(x)"
              @delete="deleteFopStepsTemplate(x)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <FopStepsModal
      v-model="modal.fopstepstemplate"
      :visible.sync="modal.visible"
      :goal="modal.goal"
      @done="modalDone()"
    />
  </div>
</template>

<script>
import FopStepsCard from "@/components/Fop/FopStepsCard";
import FopStepsModal from "@/components/Fop/FopStepsModal";

export default {
  components: { FopStepsCard, FopStepsModal },
  data: function () {
    return {
      fopstepstemplates: [],
      modal: {
        visible: false,
        goal: "",
        fopstepstemplate: { name: "", steps: [] },
      },
      loading: true,
      search: "",
      groups: [],
    };
  },
  computed: {
    getFopStepsTemplates() {
      let reg = new RegExp(this.search, "i");
      return this.fopstepstemplates.filter((x) => x.name.match(reg));
    },
  },
  created() {
    this.loadFopStepsTemplates();
    this.loadGroups();
  },
  methods: {
    modalCreate() {
      this.modal = {
        goal: "create",
        visible: true,
        fopstepstemplate: {
          name: "",
          steps: [],
        },
      };
    },
    modalEdit(fopstepstemplate) {
      this.modal = {
        goal: "edit",
        visible: true,
        fopstepstemplate,
      };
    },
    modalDone() {
      this.modal.visible = false;
      this.loadFopStepsTemplates();
    },
    loadGroups() {
      return this.axiosPost(
        process.env.VUE_APP_API_URL + "/admin/getAllGroups"
      ).then((res) => {
        this.groups = res.data;
      });
    },
    loadFopStepsTemplates() {
      this.loading = true;
      return this.axiosPost(
        process.env.VUE_APP_API_URL + "/fops/getFOPStepsTemplates"
      ).then((res) => {
        this.loading = false;
        this.fopstepstemplates = res.data;
      });
    },
    deleteFopStepsTemplate(fopstepstemplate) {
      this.$alert.$emit("confirm", {
        title: "Supprimer " + fopstepstemplate.name + " ?",
        message:
          "Supprimer le chemin de validation ! Cette action est irréversible !",
        callback: () => {
          return this.axiosPost(
            process.env.VUE_APP_API_URL + "/admin/deleteFopStepsTemplate",
            { id: fopstepstemplate._id }
          ).then(() => {
            this.loadFopStepsTemplates();
          });
        },
      });
    },
  },
};
</script>
