<template>
  <v-dialog
    v-model="visibleValue"
  >
    <v-card class="pa-3">
      <v-card-title>
        <v-chip
          color="teal"
          class="mr-4"
          label
          text-color="white"
          small
        >
          {{ goal == "create" ? "Créer" : "Modifier" }}
        </v-chip>
        <span>
          Chemin de validation
        </span>
      </v-card-title>
      <v-card-text>
        <p v-if="errors.length">
          <b>Erreur{{ errors.length > 1 ? 's':'' }} :</b>
          <ul>
            <li
              v-for="(err,i) in errors"
              :key="i"
            >
              {{ err }}
            </li>
          </ul>
        </p>

        <v-text-field
          v-model="value.name"
          outlined
          dense
          label="Nom du chemin de validation"
          :rules="[(x) => !!x || 'Nom nécessaire !']"
        />

        <EventTimeline
          :event.sync="event"
          edit
        />

        <div
          class="d-flex"
          style="margin-top:20px"
        >
          <v-btn
            color="primary"
            class="mx-auto"
            :disabled="!value.name"
            @click="submit()"
          >
            Valider
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EventTimeline from "@/components/Event/EventTimeline";

export default {
  components: { EventTimeline },
  props: {
    visible: Boolean,
    value: {
      type: Object,
      required: true,
    },
    goal: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      errors: [],
      event: {
        fop: {
          steps: [],
        },
      },
    };
  },
  computed: {
    visibleValue: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      },
    },
  },
  watch: {
    value() {
      this.event.fop.steps = this.value.steps.map((step) => ({
        _id: step._id,
        group: { _id: step.group },
        optional: step.optional,
        level: step.level,
      }));
    },
  },
  methods: {
    submit() {
      this.errors = [];
      if (!this.value.name || this.value.name == "")
        this.errors.push("Le chemin de validation n'a pas de nom.");

      this.value.steps = this.event.fop.steps.map((step) => ({
        group: step.group._id,
        level: step.level,
        optional: step.optional,
      }));

      if (this.errors.length > 0) return;
      if (this.goal == "create") this.createFopStepsTemplate(this.value);
      if (this.goal == "edit") this.updateFopStepsTemplate(this.value);
    },
    updateFopStepsTemplate(template) {
      return this.axiosPost(
        process.env.VUE_APP_API_URL + "/admin/updateFopStepsTemplate",
        template
      ).then(() => {
        this.$emit("done");
      });
    },
    createFopStepsTemplate(template) {
      return this.axiosPost(
        process.env.VUE_APP_API_URL + "/admin/createFopStepsTemplate",
        template
      ).then(() => {
        this.$emit("done");
      });
    },
  },
};
</script>